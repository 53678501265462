import React from "react";
import { GetPlayerDocument } from "../../graphql/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { PreloadingLink } from "./PreloadingLink";

export function PlayerLink(props: {
  children?: React.ReactNode | undefined;
  playerId?: string;
  dontPreload?: boolean;
  anchor?: boolean;
  newTab?: boolean;
}) {
  const client = useApolloClient();
  const query = React.useCallback(
    () =>
      props.playerId
        ? client.query({
            query: GetPlayerDocument,
            variables: {
              id: props.playerId,
            },
          })
        : () => null,
    [props.playerId, client]
  );

  return (
    <PreloadingLink
      query={props.dontPreload ? undefined : query}
      href={`/player/profile/${props.playerId}`}
      passHref
      anchor={props.anchor}
      newTab={props.newTab}
    >
      {props.children}
    </PreloadingLink>
  );
}
