import { Typography, useTheme } from "@mui/material";

export const AlertsCell = (props: { alerts?: string }) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Typography
      fontSize={12}
      fontWeight={500}
      color={colors.grey[600]}
      maxWidth={"100%"}
      sx={{ whiteSpace: "wrap" }}
    >
      {props.alerts}
    </Typography>
  );
};
