import { Typography, useTheme } from "@mui/material";

export const FitsCell = (props: { fits?: string }) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Typography
      fontSize={12}
      fontWeight={500}
      color={colors.grey[600]}
      maxWidth={"100%"}
      whiteSpace={"nowrap"}
      overflow={"hidden"}
      textOverflow={"ellipsis"}
    >
      <span title={props.fits?.toString() ?? "-"}>{props.fits}</span>
    </Typography>
  );
};
