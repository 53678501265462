import React from "react";
import { GetSchoolDocument } from "../../graphql/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { PreloadingLink } from "./PreloadingLink";

export function SchoolLink(props: {
  children?: React.ReactNode | undefined;
  schoolId?: string;
  dontPreload?: boolean;
  anchor?: boolean;
}) {
  const client = useApolloClient();
  const query = React.useCallback(
    () =>
      props.schoolId
        ? client.query({
            query: GetSchoolDocument,
            variables: {
              id: props.schoolId,
            },
          })
        : () => null,
    [props.schoolId, client]
  );

  return (
    <PreloadingLink
      query={props.dontPreload ? undefined : query}
      href={`/school/${props.schoolId}`}
      passHref
      anchor={props.anchor}
    >
      <span
        style={{
          color: "rgb(0, 133, 202)",
          cursor: "pointer",
          fontSize: "1em",
          fontWeight: 400,
          lineHeight: 1.5,
          letterSpacing: "0.00938em",
        }}
      >
        {props.children}
      </span>
    </PreloadingLink>
  );
}
