import { Stack, Typography, useTheme } from "@mui/material";
import { ClubLogoImage } from "@sumer/shared/components/design/ClubLogoImage";
import { ClubLink } from "../../prelinks/ClubLink";
interface ClubCellProps {
  clubCode: string;
  clubName: string;
  id: string;
  displayLogo?: boolean;
}

export const ClubCell = ({
  clubCode,
  clubName,
  id,
  displayLogo = true,
}: ClubCellProps) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Stack flexDirection="row" alignItems="center">
      {displayLogo && <ClubLogoImage clubCode={clubCode} height={25} />}
      <Typography
        fontSize={14}
        fontWeight={500}
        color={colors.grey[600]}
        ml={0.5}
      >
        <ClubLink clubId={id} dontPreload>
          {clubName}
        </ClubLink>
      </Typography>
    </Stack>
  );
};
