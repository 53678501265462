import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { GetMeasureFraction } from "../../utils/measures";

export const MeasurableValue = (props: {
  value?: number | string;
  verified?: boolean;
  soft?: boolean;
  isFraction?: boolean;
  includeVerification?: boolean;
}) => {
  const theme = useTheme();
  const colors = theme.palette;
  let measure = props.value;
  if (props.isFraction) {
    measure = GetMeasureFraction(props.value);
  }
  const showVerified = props.includeVerification ?? true;

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography
        fontSize={".8rem"}
        fontWeight={props.soft ? 400 : 700}
        color={colors.grey[700]}
        noWrap
      >
        {measure ?? <span>--</span>}
      </Typography>
      {measure && measure !== "--" && showVerified && (
        <Verified verified={props.verified} soft={props.soft} />
      )}
    </Stack>
  );
};
const Verified = (props: { verified?: boolean; soft?: boolean }) => {
  const theme = useTheme();
  const colors = theme.palette;

  if (props.verified) {
    return (
      <Typography
        fontSize={".8rem"}
        fontWeight={props.soft ? 400 : 700}
        color={colors.grey[700]}
      >
        v
      </Typography>
    );
  }

  return (
    <Typography
      fontSize={".8rem"}
      fontWeight={props.soft ? 400 : 700}
      color={colors.grey[700]}
    >
      e
    </Typography>
  );
};
export const CellValue = (props: {
  value?: number | string;
  soft?: boolean;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Typography
      fontSize={".8rem"}
      fontWeight={props.soft ? 400 : 700}
      color={colors.grey[600]}
      maxWidth={"100%"}
      whiteSpace={"nowrap"}
      overflow={"hidden"}
      textOverflow={"ellipsis"}
    >
      <span title={props.value?.toString() ?? "--"}>{props.value ?? "--"}</span>
    </Typography>
  );
};
