import { Avatar, Stack, Typography, colors } from "@mui/material";
import { getPlayerJerseyDisplay } from "../../../utils/players";
import { PlayerLink } from "../../prelinks/PlayerLink";
import { PlayerOutlineIcon } from "../icons/PlayerOutlineIcon";

export const NameCell = (props: {
  id: string;
  jersey?: string | null | undefined;
  first: string;
  last: string;
  headshot?: string | null;
  anchor?: boolean;
  newTab?: boolean;
}) => {
  const playerName = props.first + " " + props.last;
  const playerHeadshot = props.headshot
    ? props.headshot?.replace("{formatInstructions}", `c_fit,h_${100},w_${100}`)
    : null;

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center">
      {playerHeadshot ? (
        <Avatar
          src={playerHeadshot}
          alt="player headshot"
          sx={{
            width: "30px",
            height: "30px",
            border: 1,
            borderColor: colors.grey[300],
          }}
        >
          {/* If missing, it will default to this versus a letter */}
          <PlayerOutlineIcon
            sx={{
              width: "30px",
              height: "30px",
              padding: "4px",
              border: 1,
              color: colors.grey[600],
              borderColor: colors.grey[300],
              backgroundColor: colors.grey[50],
              borderRadius: "50%",
            }}
          />
        </Avatar>
      ) : (
        <PlayerOutlineIcon
          sx={{
            width: "30px",
            height: "30px",
            padding: "4px",
            border: 1,
            color: colors.grey[600],
            borderColor: colors.grey[300],
            backgroundColor: colors.grey[50],
            borderRadius: "50%",
          }}
        />
      )}
      <Stack flexDirection="row" alignItems="center">
        {props.jersey && (
          <>
            <Typography fontSize=".9rem" fontWeight={700} ml={0.5}>
              {getPlayerJerseyDisplay(props.jersey)}
            </Typography>
            <Typography fontSize=".9rem" fontWeight={500} ml={0.5}>
              |
            </Typography>
          </>
        )}
        <Typography fontSize=".9rem" fontWeight={700} ml={0.5}>
          {props.newTab ? (
            <PlayerLink playerId={props.id} dontPreload newTab anchor>
              {playerName}
            </PlayerLink>
          ) : (
            <PlayerLink playerId={props.id} dontPreload>
              {playerName}
            </PlayerLink>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};
