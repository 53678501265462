import { MeasurableValue } from "@sumer/shared/components/design/ColumnValues";

export const MeasureCell = (props: {
  value?: number | string;
  fallbackValue?: number | string | null | undefined;
  verified?: boolean;
  soft?: boolean;
  isFraction?: boolean;
  includeVerification?: boolean;
}) => {
  const measureValue = props.value ?? props.fallbackValue ?? undefined;
  return (
    <MeasurableValue
      value={measureValue}
      verified={props.verified}
      soft={props.soft}
      isFraction={props.isFraction}
      includeVerification={props.includeVerification}
    />
  );
};
