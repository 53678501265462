import React from "react";
import { GetClubDocument } from "../../graphql/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { PreloadingLink } from "./PreloadingLink";

export function ClubLink(props: {
  children?: React.ReactNode | undefined;
  clubId?: string;
  dontPreload?: boolean;
  anchor?: boolean;
}) {
  const client = useApolloClient();
  const query = React.useCallback(
    () =>
      props.clubId
        ? client.query({
            query: GetClubDocument,
            variables: {
              id: props.clubId,
            },
          })
        : () => null,
    [props.clubId, client]
  );

  return (
    <PreloadingLink
      query={props.dontPreload ? undefined : query}
      href={`/club/${props.clubId}`}
      passHref
      anchor={props.anchor}
    >
      <span
        style={{
          color: "rgb(0, 133, 202)",
          cursor: "pointer",
          fontSize: "1em",
          fontWeight: 400,
          lineHeight: 1.5,
          letterSpacing: "0.00938em",
        }}
      >
        {props.children}
      </span>
    </PreloadingLink>
  );
}
