const EIGHTH_FACTOR = 8;

export const GetMeasureFraction = (
  decimal: number | string | undefined | null
) => {
  if (!decimal || typeof decimal === "string") return undefined;
  const wholeNumber = Math.floor(decimal);
  const fraction = decimal - wholeNumber;

  if (fraction == 0) return wholeNumber;

  const numerator = Math.round(fraction * EIGHTH_FACTOR);
  const denominator = EIGHTH_FACTOR; //we are dealing with eighths

  const gcd = getGreatestCommonDivisior(numerator, denominator);
  return `${wholeNumber} ${numerator / gcd}/${denominator / gcd}`;
};

const getGreatestCommonDivisior = (a: number, b: number): number => {
  if (b === 0) {
    return a;
  }
  return getGreatestCommonDivisior(b, a % b);
};
export const getWorkoutSource = (comments: string, workoutType: string) => {
  if (workoutType == "AllStar") {
    if (comments.indexOf("Hula Bowl") != -1) {
      return "All-Star (Hula)";
    }
    if (comments.indexOf("Senior Bowl") != -1) {
      return "All-Star (Senior)";
    }
  }
  return workoutType;
};

export const getBroadJumpFeetInches = (valueInInches: number | undefined) => {
  if (valueInInches) {
    const feet = Math.floor(valueInInches / 12);
    const inches = valueInInches % 12;
    return `${feet}-${inches}`;
  }
  return valueInInches;
};
