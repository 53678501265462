import React, { useEffect } from "react";
import Link, { LinkProps } from "next/link";

export function PreloadingLink({
  query,
  anchor,
  newTab,
  ...rest
}: {
  anchor?: boolean;
  query?: () => void;
  newTab?: boolean;
} & React.PropsWithChildren<LinkProps>) {
  useEffect(() => {
    if (query) query();
  }, [query]);

  if (anchor) {
    return (
      <Link {...rest} target={newTab ? "_blank" : undefined}>
        {rest.children}
      </Link>
    );
  }
  return <Link {...rest} legacyBehavior>{rest.children}</Link>;
}
