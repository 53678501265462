import { useTheme } from "@mui/material";

export const useDataGridStyles = () => {
  const theme = useTheme();
  return {
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "&.MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-cell": {
      borderBottomColor: `${theme.palette.grey[100]} !important`,
    },
    "& .MuiDataGrid-main": {
      border: "1px solid #d9dcdf",
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .header-styling:focus-within, & .column-header:focus-within": {
      outline: "none",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: theme.palette.grey[50],
      borderBottomColor: theme.palette.grey[200],
      outline: "none",
    },
    "& .MuiDataGrid-row": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      background: theme.palette.common.white,
      width: "16px",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
      background: theme.palette.common.white,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      background: "#babac0",
      borderRadius: "16px",
      border: `4px solid ${theme.palette.common.white}`,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };
};
