import { Stack, Typography, useTheme } from "@mui/material";
import { SchoolLogoImage } from "@sumer/shared/components/design/SchoolLogoImage";
import { SchoolLink } from "../../prelinks/SchoolLink";

interface SchoolCellProps {
  schoolCode: string;
  id: string;
  displayLogo?: boolean;
}

export const SchoolCell = ({
  schoolCode,
  id,
  displayLogo = true,
}: SchoolCellProps) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Stack flexDirection="row" alignItems="center">
      {displayLogo && <SchoolLogoImage schoolCode={schoolCode} height={25} />}
      <Typography
        fontSize={14}
        fontWeight={500}
        color={colors.grey[600]}
        ml={0.5}
      >
        <SchoolLink schoolId={id} dontPreload>
          {schoolCode}
        </SchoolLink>
      </Typography>
    </Stack>
  );
};
