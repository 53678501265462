import { Stack, Typography, useTheme } from "@mui/material";

export const RankCell = (props: { rank?: number | null }) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography
        fontSize={14}
        fontWeight={500}
        color={colors.grey[600]}
        ml={0.5}
      >
        {props.rank}
      </Typography>
    </Stack>
  );
};
